// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBJT42vTERvhcdOwSCjB4nCfKl-8v4U1LA",
  authDomain: "yvomnewa1st.firebaseapp.com",
  databaseURL: "https://yvomnewa1st-default-rtdb.firebaseio.com",
  projectId: "yvomnewa1st",
  storageBucket: "yvomnewa1st.appspot.com",
  messagingSenderId: "591951028655",
  appId: "1:591951028655:web:9215cca057fd6abc954f5a",
  measurementId: "G-YHSK72G5TK"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };